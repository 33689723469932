import React, { useState } from "react";
import "./LostPlus.css";
import { useNavigate } from "react-router-dom"; // useNavigate 훅을 react-router-dom에서 불러옵니다.
import { postLost } from "../../apis/axios";
import Background from "../../components/Background/Background";
import { Link } from "react-router-dom"; // Link를 react-router-dom에서 불러옵니다.

const LostPlus = () => {
  const Spacer = () => {
    return <div style={{ height: "20%", width: "60px" }} />;
  };

  const [item_name, setTitle] = useState("");
  const [found_in, setLocation] = useState("");
  const [file, setImage] = useState(null);

  const Router = useNavigate(); // useNavigate 훅을 사용

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("item_name", item_name);
    formData.append("found_in", found_in);
    if (file) {
      // 이 부분도 image -> file로 변경했습니다.
      formData.append("file", file);
    }

    try {
      await postLost(item_name, found_in, file);
      alert("업로드 성공");
      Router("/lost");
    } catch (error) {
      alert(error.response ? error.response.data.message : "업로드 실패"); // BiSolidCommentError를 alert로 변경했습니다.
      console.log(error);
    }
  };

  return (
    <div className="lp-fm">
      <Background />
      <div className="lp_header">
        <div className="lp-header-left">
          <Link to="/lost" style={{ color: "white", textDecoration: "none" }}>
            &lt;
          </Link>
        </div>
        <div className="lp-header-mid">분실물 추가</div>
        <div className="header-right">
          <Spacer />
        </div>
      </div>
      <div className="LP-wrapper">
        <form onSubmit={handleSubmit} className="lostAdminForm">
          <h2>분실물 이름</h2>
          <input
            type="text"
            value={item_name}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목"
            required
          />
          <h2>발견 위치</h2>
          <input
            type="text"
            value={found_in}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="위치"
            required
          />
          <h2>이미지 추가</h2>
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/jpg, image/png"
          />
          <button type="submit" className="pluss-button">
            추가 하기
          </button>
        </form>
      </div>
    </div>
  );
};

export default LostPlus;
