import React, { useState, useEffect } from "react";
import "./Info.css";
import Background from "../../components/Background/Background";
import DropdownMenu from "../../pages/DropDownMenu/DropDownMenu";
import { Link } from "react-router-dom";
import { getInfo, deleteInfo } from "../../apis/axios";
import InfoPlus from "../../assets/images/Plus2.png";
const Info = () => {
  const [notices, setNotices] = useState([]);
  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const data = await getInfo();
        setNotices(data.notices);
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };
    fetchNotices();
  }, []);
  const Spacer = () => {
    return <div style={{ height: "20%", width: "60px" }} />;
  };
  const [activeMenu, setActiveMenu] = useState(null);

  const handleMenuClick = (index) => {
    setActiveMenu(index === activeMenu ? null : index);
  };

  const handleEdit = (index) => {
    alert(`공지 ${index + 1} 수정`);
    setActiveMenu(null); // 액션 후 메뉴 닫기
  };

  const handleDelete = async (id, title) => {
    const confirmDelete = window.confirm(`${title} 삭제하시겠습니까?`); // 확인 대화상자 추가

    if (confirmDelete) {
      // 사용자가 확인을 눌렀을 때만 삭제 진행
      try {
        await deleteInfo(id);
        setActiveMenu(null);
        window.location.reload(); // 페이지 새로고침
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div>
      <Background />
      <div className="info_header">
        <div className="header-left">
          <Link to="/host" style={{ color: "white", textDecoration: "none" }}>
            &lt;
          </Link>
        </div>
        <div className="info-header-mid">공지사항</div>
        <div className="info-header-right">
          <Spacer />
        </div>
      </div>
      <div className="Info_container">
        <div className="Info_wrapper">
          {notices.map((item, index) => (
            <div key={item.NoticeID} className="Info_box">
              {item.Title}
              <div className="menu-icon" onClick={() => handleMenuClick(index)}>
                &#x22EE;
              </div>
              {activeMenu === index && (
                <DropdownMenu
                  onEdit={() => handleEdit(item.Title)}
                  onDelete={() => handleDelete(item.NoticeID, item.Title)}
                  id={item.NoticeID}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <Link to="/infoplus" className="Info_plus">
        <img className="InfoPlus" src={InfoPlus} alt="공지사항 추가" />
      </Link>
    </div>
  );
};

export default Info;
