import React, { useState, useEffect } from "react";
import "./Lost.css";
import Background2 from "../../components/Background/Background2";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import LostPlus from "../../assets/images/Plus.png";
import { getLost, deleteLost } from "../../apis/axios"; // deleteLost 추가
import DropdownMenu from "../DropDownMenu/DropDownMenu"; // DropdownMenu 컴포넌트 추가

const Lost = () => {
  const [lostItems, setLostItems] = useState([]);
  const [search, setSearch] = useState("");
  const [activeMenu, setActiveMenu] = useState(null); // activeMenu 상태 추가

  useEffect(() => {
    const fetchLostItems = async () => {
      try {
        const data = await getLost();
        setLostItems(data.lostItems);
      } catch (error) {
        console.error("분실물 불러오기 오류:", error);
      }
    };
    fetchLostItems();
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  // handleEdit 함수: 수정 기능 추가
  const handleEdit = (title) => {
    console.log(`${title} 항목을 수정합니다.`);
    // 여기에서 수정 기능을 구현
  };

  // handleDelete 함수: 삭제 기능 추가
  const handleDelete = async (itemID, title) => {
    const confirmation = window.confirm(
      `"${title}" 게시물을 삭제하시겠습니까?`
    );
    if (!confirmation) return;

    try {
      await deleteLost(itemID); // 서버에 삭제 요청
      alert("분실물 게시물이 성공적으로 삭제되었습니다.");
      setLostItems((prevItems) =>
        prevItems.filter((item) => item.ItemID !== itemID)
      ); // UI 업데이트
    } catch (error) {
      console.error("분실물 게시물 삭제 중 에러가 발생했습니다.", error);
      alert("삭제하는 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  // Filtered lost items based on search input
  const filteredLostItems = lostItems.filter(
    (item) =>
      item.ItemName.toLowerCase().includes(search.toLowerCase()) ||
      item.FoundIn.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="lostMain">
      <Background2 />
      <div className="fotune_header">
        <div className="header-left">
          <Link to="/host" style={{ color: "white", textDecoration: "none" }}>
            &lt;
          </Link>
        </div>
        <div className="header-mid">분실물</div>
        <div className="header-right"></div>
      </div>
      <div className="lostSearch">
        <input
          type="text"
          className="lostSearch2"
          value={search}
          onChange={onChange}
          placeholder="검색어를 입력하세요"
        />
        <FaSearch className="lostIcon2" />
      </div>
      <div className="lostItemsContainer">
        {filteredLostItems.length > 0 ? (
          filteredLostItems.map((item, index) => (
            <div className="item1" key={item.ItemID}>
              {item.Image && item.Image.ImageURL && (
                <img
                  src={item.Image.ImageURL}
                  alt={item.ItemName}
                  className="lostImg"
                />
              )}
              <div className="title-wrapper">
                <p className="bigTitle">{item.ItemName}</p>
                <p className="smallTitle">{item.FoundIn}</p>
              </div>
              {/* 클릭 시 DropdownMenu 활성화 */}
              <button
                className="LostDelete_button"
                onClick={() =>
                  setActiveMenu(activeMenu === index ? null : index)
                }
              >
                ⋮
              </button>
              {activeMenu === index && (
                <DropdownMenu
                  onEdit={() => handleEdit(item.Title)}
                  onDelete={() => handleDelete(item.ItemID, item.ItemName)}
                  id={item.ItemID}
                />
              )}
            </div>
          ))
        ) : (
          <div className="noResults">검색 결과가 없습니다.</div>
        )}
      </div>

      <Link to="/lostplus" className="Lost_plus">
        <img className="LostPlus" src={LostPlus} alt="분실물 추가" />
      </Link>
    </div>
  );
};

export default Lost;
