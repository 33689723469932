import React from "react";
import "./QR.css";
import qr from "../../assets/images/qr.jpg";

const QR = () => {
  return (
    <div className="qr">
      <img className="qr-image" src={qr} alt="qr코드" />
    </div>
  );
};

export default QR;
