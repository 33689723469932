import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // useNavigate 가져오기
import Background from "../../components/Background/Background";
import "./Host.css";
import treasure from "../../assets/images/treasure.png";
import Lost from "../../assets/images/Lost.png";
import Info from "../../assets/images/Info.png";
import { Link } from "react-router-dom";
import { getTreasures, getLost, getInfo } from "../../apis/axios.js";

const Host = () => {
  const [treasures, setTreasures] = useState([]);
  const [lostItems, setLostItems] = useState([]);
  const [notices, setNotices] = useState([]);

  const navigate = useNavigate(); // navigate 사용하기

  useEffect(() => {
    const fetchTreasures = async () => {
      try {
        const data = await getTreasures();
        setTreasures(data.treasures);
      } catch (error) {
        console.error("Error fetching treasures:", error);
      }
    };

    fetchTreasures();
  }, []);

  useEffect(() => {
    const fetchLostItems = async () => {
      try {
        const data = await getLost();
        setLostItems(data.lostItems);
      } catch (error) {
        console.error("Error fetching lost items:", error);
      }
    };

    fetchLostItems();
  }, []);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const data = await getInfo();
        setNotices(data.notices);
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };
    fetchNotices();
  }, []);

  const adjustedTreasureCount = () => {
    let count = treasures.length; // Start with the total number of treasures
    treasures.forEach((treasure) => {
      if (treasure.TreasureCount === 0) {
        count -= 1; // Subtract one if TreasureCount is 0
      }
    });
    return count;
  };

  // 로그아웃 함수
  const handleLogout = () => {
    // 로컬 스토리지나 세션 스토리지에서 토큰 제거
    localStorage.removeItem("accessToken");

    // 안내 문구를 표시
    alert("로그아웃되었습니다.");

    // 로그인 페이지로 이동
    navigate("/");
  };

  return (
    <div>
      <Background />
      <div className="Host_wrapper">
        <div className="Host_header">관리자 모드</div>

        <div className="Host_middle">
          <div className="mid-all">
            <div className="mid1">
              <div className="mid-left">
                <img className="tr" src={treasure} alt="보물상자" />
                보물 찾기
              </div>
              <div className="mid-right">
                <div className="right-left">
                  <span>{adjustedTreasureCount()}</span>
                  <span>/</span>
                  <span>{6}</span>
                </div>
                <Link to="/fotune" className="right-right">
                  <span className="row">&gt;</span>
                </Link>
              </div>
            </div>
            <div className="mid1">
              <div className="mid-left">
                <img className="lost" src={Lost} alt="분실물" />
                분실물
              </div>
              <div className="mid-right">
                <div className="right-left">
                  <span>{lostItems.length}</span>
                </div>
                <Link to="/lost" className="right-right">
                  <span className="row">&gt;</span>
                </Link>
              </div>
            </div>
            <div className="mid1">
              <div className="mid-left">
                <img className="info" src={Info} alt="공지사항" />
                공지사항
              </div>
              <div className="mid-right">
                <div className="right-left">
                  <span>{notices.length}</span>
                </div>
                <Link to="/info" className="right-right">
                  <span className="span_row">&gt;</span>
                </Link>
              </div>
            </div>
          </div>
          <input
            className="LogOut_button"
            type="submit"
            value="로그아웃"
            onClick={handleLogout} // 로그아웃 버튼 클릭 시 handleLogout 실행
          />
        </div>
      </div>
    </div>
  );
};

export default Host;
