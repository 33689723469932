import React, { useState, useEffect } from "react";
import "./Fotune.css";
import Background from "../../components/Background/Background";
import { Link } from "react-router-dom";
import {
  incrementTreasureCount,
  decrementTreasureCount,
  getTreasures, // 보물 데이터를 가져오는 API 함수
} from "../../apis/axios"; // API 함수 가져오기

const Fotune = () => {
  const [treasures, setTreasures] = useState([]); // 보물 데이터를 저장할 상태값

  useEffect(() => {
    // 페이지 로드 시 보물 데이터를 가져오는 함수 호출
    const fetchTreasures = async () => {
      try {
        const data = await getTreasures(); // API 호출하여 보물 데이터를 가져옴
        setTreasures(data.treasures); // 보물 데이터를 상태로 저장
      } catch (error) {
        console.error("Error fetching treasures:", error);
      }
    };

    fetchTreasures(); // 보물 데이터 가져오기
  }, []);

  // count 증가 함수
  const increment = async (treasureID) => {
    try {
      await incrementTreasureCount(treasureID); // 보물 카운트 증가 API 호출
      window.location.reload(); // 페이지 새로고침
    } catch (error) {
      console.error("Error incrementing treasure count:", error);
    }
  };

  // count 감소 함수
  const decrement = async (treasureID) => {
    try {
      await decrementTreasureCount(treasureID); // 보물 카운트 감소 API 호출
      window.location.reload(); // 페이지 새로고침
    } catch (error) {
      console.error("Error decrementing treasure count:", error);
    }
  };

  const colorss = [
    "#ffaead",
    "#ffd7a6",
    "#fdffb6",
    "#cbffbf",
    "#9ec5ff",
    "#bdb2ff",
  ]; // 사용할 색상 배열

  return (
    <div>
      <Background />
      <div className="fotune_header">
        <div className="header-left">
          <Link to="/host" style={{ color: "white", textDecoration: "none" }}>
            &lt;
          </Link>
        </div>
        <div className="header-mid">보물찾기</div>
        <div className="header-right"></div>
      </div>
      <div className="fotune_wrapper">
        {treasures.map((treasure, index) => (
          <div
            key={treasure.TreasureID}
            className="f_box"
            style={{ backgroundColor: colorss[index % colorss.length] }} // 인덱스 기반 배경 색상
          >
            <img
              className="f_img"
              src={treasure.ImageURL} // 이미지 URL을 불러옴
              alt={treasure.TreasureName}
            />
            {treasure.TreasureName} (남은 개수: {treasure.TreasureCount})
            <div className="count_button">
              <button onClick={() => decrement(treasure.TreasureID)}>-</button>
              <h2>{treasure.TreasureCount}</h2>
              <button
                onClick={() => increment(treasure.TreasureID)}
                style={{ marginLeft: "10px" }}
              >
                +
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Fotune;
