import React, { useState } from "react";
import "./InfoPlus.css";
import Background from "../../components/Background/Background";
import { Link, useNavigate } from "react-router-dom";
import { postInfo } from "../../apis/axios";

const Spacer = () => {
  return <div style={{ height: "20%", width: "60px" }} />;
};

const InfoPlus = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await postInfo(title, content); // 공지사항 업로드 요청
      alert("업로드 성공");
      navigate("/info"); // 업로드 성공 시 페이지 이동
    } catch (error) {
      alert(error?.response?.data?.message || "업로드 실패");
      console.log(error);
    }
  };

  return (
    <div className="ip-fm">
      <Background />
      <div className="Ip_header">
        <div className="Ip-header-left">
          <Link to="/info" style={{ color: "white", textDecoration: "none" }}>
            &lt;
          </Link>
        </div>

        <div className="Ip-header-mid">공지사항 추가</div>
        <div className="Ip-header-right">
          <Spacer />
        </div>
      </div>
      <div className="IP-wrapper">
        <form onSubmit={handleSubmit} className="lostAdminForm">
          <h2>제목</h2>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="공지사항 제목을 입력하세요."
          />

          <h2>본문</h2>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="본문 내용을 입력하세요."
          ></textarea>

          <button type="submit" className="IpPlus-button">
            추가 하기
          </button>
        </form>
      </div>
    </div>
  );
};

export default InfoPlus;
