import axiosInstance from "./axiosInstance";

// 액세스 토큰을 헤더에 추가하는 함수
const getAuthHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
export const postLogin = async (user_id, user_password) => {
  try {
    const response = await axiosInstance.post("/auth/login", {
      user_id,
      user_password,
    });

    const { accessToken, refreshToken } = response.data;

    // 액세스 토큰은 메모리에 저장 (보안을 위해 localStorage 대신 메모리에 저장하는 것이 권장됩니다)
    localStorage.setItem("accessToken", accessToken);
    // 리프레시 토큰은 로컬 스토리지에 저장
    localStorage.setItem("refreshToken", refreshToken);
    return { accessToken, refreshToken };
  } catch (error) {
    throw error;
  }
};

// 분실물 신고 요청
export const postLost = async (item_name, found_in, file) => {
  try {
    const formData = new FormData();
    formData.append("item_name", item_name);
    formData.append("found_in", found_in);
    formData.append("file", file);

    const response = await axiosInstance.post("/lostItems/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getAuthHeaders(), // 헤더에 토큰 추가
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error posting lost item:", error);
    throw error;
  }
};
// 공지사항 등록 요청
export const postInfo = async (title, content) => {
  try {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);

    const response = await axiosInstance.post("/notices/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getAuthHeaders(), // 헤더에 토큰 추가
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error posting info:", error);
    throw error;
  }
};

export const getLost = async () => {
  try {
    const response = await axiosInstance.get("/lostItems/");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTreasures = async () => {
  try {
    const response = await axiosInstance.get("/treasures/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInfo = async () => {
  try {
    const response = await axiosInstance.get("/notices/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteInfo = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
    if (!accessToken) {
      throw new Error("No access token found");
    }

    await axiosInstance.delete(`/notices/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`, // 액세스 토큰을 헤더에 추가
      },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized: Invalid or expired token");
    } else {
      console.error("Error deleting info:", error);
    }
    throw error;
  }
};
export const deleteLost = async (id) => {
  try {
    const accessToken = localStorage.getItem("accessToken"); // 액세스 토큰 가져오기
    if (!accessToken) {
      throw new Error("No access token found");
    }

    await axiosInstance.delete(`/lostItems/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`, // 액세스 토큰을 헤더에 추가
      },
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized: Invalid or expired token");
    } else {
      console.error("Error deleting info:", error);
    }
    throw error;
  }
};
// 보물 카운트 증가 요청 (새로 추가된 부분)
export const incrementTreasureCount = async (treasureID) => {
  try {
    const accessToken = localStorage.getItem("accessToken");

    const response = await axiosInstance.put(
      `/treasures/${treasureID}/increment`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

//보물 카운트 감소 요청 (새로 추가된 부분)
export const decrementTreasureCount = async (treasureID) => {
  try {
    // 액세스 토큰을 로컬 스토리지에서 가져옴
    const accessToken = localStorage.getItem("accessToken");

    const response = await axiosInstance.put(
      `/treasures/${treasureID}/decrement`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`, // 액세스 토큰을 헤더에 포함
        },
      }
    );

    if (response.status === 204) {
      console.log("Treasure count successfully decreased");
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized: Invalid or expired token");
    } else {
      console.error("Error:", error);
    }
    throw error;
  }
};

export const getTreasuresData = async () => {
  try {
    const response = await axiosInstance.get("/treasures/");

    // treasures 데이터는 response.data.treasures 에 들어 있습니다.
    const treasures = response.data.treasures;

    console.log("보물찾기 보물 데이터를 성공적으로 불러왔습니다:", treasures);

    // treasures 데이터를 반환하거나 필요한 다른 처리를 할 수 있습니다.
    return treasures;
  } catch (error) {
    console.error("보물 데이터를 불러오는 중 오류 발생:", error);

    // 오류 처리 로직 추가
    throw error;
  }
};
