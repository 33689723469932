import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { initVh } from "../src/utils/setVh";
import React, { useEffect } from "react";
import QR from "./pages/QR/QR.jsx";
import Login from "./pages/Login/Login.jsx";
import Host from "./pages/Host/Host.jsx";
import Info from "./pages/Info/Info.jsx";
import Fotune from "./pages/Fotune/Fotune.jsx";
import Lost from "./pages/Lost/Lost.jsx";
import LostPlus from "./pages/Lost/LostPlus.jsx";
import InfoPlus from "./pages/Info/InfoPlus.jsx";

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  return token ? children : <Navigate to="/" />;
};

function App() {
  useEffect(() => {
    const cleanup = initVh(); //initVh() cleanup에 대입
    return cleanup;
  }, []);
  return (
    <>
      <QR />

      <div className="phone_size">
        <Routes>
          <Route path="/" exact={true} element={<Login />} />
          <Route
            path="/host"
            element={
              <PrivateRoute>
                <Host />
              </PrivateRoute>
            }
          />
          <Route
            path="/info"
            element={
              <PrivateRoute>
                <Info />
              </PrivateRoute>
            }
          />
          <Route
            path="/lost"
            element={
              <PrivateRoute>
                <Lost />
              </PrivateRoute>
            }
          />
          <Route
            path="/fotune"
            element={
              <PrivateRoute>
                <Fotune />
              </PrivateRoute>
            }
          />
          <Route
            path="/lostplus"
            element={
              <PrivateRoute>
                <LostPlus />
              </PrivateRoute>
            }
          />
          <Route
            path="/infoplus"
            element={
              <PrivateRoute>
                <InfoPlus />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
